import React from 'react';

// const SignUp1 = React.lazy(() => import('./Demo/Authentication/SignUp/SignUp1'));
// const Signin1 = React.lazy(() => import('./Demo/Authentication/SignIn/SignIn1'));
//
// const route = [
//     { path: '/auth/signup-1', exact: true, name: 'Signup 1', component: SignUp1 },
//     { path: '/auth/signin-1', exact: true, name: 'Signin 1', component: Signin1 }
// ];

// const SignUp = React.lazy(() => import('./Ungkapin/Front/SignUp'));
// const SignIn = React.lazy(() => import('./Ungkapin/Front/SignIn'));
// const Hello = React.lazy(() => import('./Ungkapin/Front/Hello'));
const Landing = React.lazy(() => import('./Ungkapin/Front/Landing'));
const History = React.lazy(() => import('./Ungkapin/Front/History'));

const route = [
    // { path: '/register', exact: true, name: 'Signup', component: SignUp },
    // { path: '/login', exact: true, name: 'Signin', component: SignIn },
    // { path: '/hello', exact: true, name: 'Hello', component: Hello },
    { path: '/', exact: true, name: 'Landing', component: Landing },
    { path: '/history', exact: true, name: 'History', component: History},
];

export default route;